import React, { useState, useEffect } from 'react';
import classes from './CompanyInformation.module.css';

const CompanyInformation = (props) => {
    console.log('props.squad_setting', props.squad_setting);
    
    const [companyData, setCompanyData] = useState({});
    const [phoneNo, setPhoneNo] = useState('');
    const [prevNo, setPrevNo] = useState('');
    const [assistants, setAssistants] = useState([]); // State to hold the dynamic assistants

    useEffect(() => {
        setAssistants(props.squad_setting);
    }, [])

    useEffect(() => {
        setCompanyData(props.companyInfo);
    }, [props.companyInfo]);

    useEffect(() => {
        setPrevNo(props.companyInfo.phoneNumber);
    }, []);

    const handleCompanyInfoInput = (e) => {
        if (e.target.name === 'limit') {
            e.target.value = e.target.value > 0 ? e.target.value : '';
        }
        let tempInfo = { ...companyData };
        tempInfo[e.target.name] = e.target.value;
        props.setValue(tempInfo, 'companyInfo');
    };

    const handleDynamicNo = () => {
        let phoneToggleInfo = { ...companyData };

        if (companyData.dynamicInsertNumber === 'ON') {
            phoneToggleInfo['dynamicInsertNumber'] = 'OFF';
            phoneToggleInfo['phoneNumber'] = props.companyInfo.prevNumber;
        } else {
            phoneToggleInfo['dynamicInsertNumber'] = 'ON';
            phoneToggleInfo['phoneNumber'] = '';
        }
        props.setValue(phoneToggleInfo, 'companyInfo');
        console.log(phoneToggleInfo.dynamicInsertNumber);
    };

    const handlePhoneNo = (e) => {
        setPhoneNo(e.target.value);
        let phoneInfo = { ...companyData };
        phoneInfo['phoneNumber'] = e.target.value;
        phoneInfo['prevNumber'] = e.target.value;
        props.setValue(phoneInfo, 'companyInfo');
        console.log(phoneInfo);
    };

    const handleAddAssistant = () => {
        props.setValue([...assistants, { message: '', description: '', workspaceId: '', assistantName: '' }], 'assistantsInfo');
        setAssistants([...assistants, { message: '', description: '', workspaceId: '', assistantName: '' }]);
    };

    const handleInputChange = (index, field, value) => {
        let updatedAssistants = null;
        if (field === 'assistant') {
            const selectedAssistant = props.turboList.filter((tl) => tl.wid == value);
            console.log(selectedAssistant, value);            
            updatedAssistants = assistants.map((assistant, i) =>
                i === index ? { ...assistant, workspaceId: selectedAssistant[0].wid, assistantName: selectedAssistant[0].name } : assistant
            );
        } else {
            updatedAssistants = assistants.map((assistant, i) =>
                i === index ? { ...assistant, [field]: value } : assistant
            );
        }
        props.setValue(updatedAssistants, 'assistantsInfo');
        setAssistants(updatedAssistants);
    };

    const handleDeleteAssistant = (index) => {
        const updatedAssistants = assistants.filter((_, i) => i !== index);
        props.setValue(updatedAssistants, 'assistantsInfo');
        setAssistants(updatedAssistants);
    };

    const renderComapnyInfo = () => {
        return (
            <div className="row" un_key="0">
                {/* Company Name */}
                <label className="col-md-4 pd10botbuilder">Company name</label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input
                            type="text"
                            className="form-control"
                            name="companyName"
                            value={props.companyInfo.companyName}
                            onChange={(event) => handleCompanyInfoInput(event)}
                        />
                    </div>
                </div>

                {/* Phone Number */}
                <label className="col-md-4 pd10botbuilder">Phone number</label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input
                            disabled={companyData.dynamicInsertNumber === 'ON'}
                            type="text"
                            className="form-control"
                            name="phoneNumber"
                            value={props.companyInfo.phoneNumber}
                            onChange={(event) => handlePhoneNo(event)}
                            placeholder="Phone Number"
                        />
                    </div>
                </div>

                {/* Dynamic Number Insertion */}
                <label className="col-md-4 pd10botbuilder">Use dynamic number insertion</label>
                <div className="col-md-8">
                    <div>
                        <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                            <label>
                                <input
                                    checked={companyData.dynamicInsertNumber === 'ON'}
                                    onChange={handleDynamicNo}
                                    type="checkbox"
                                />
                                <span />
                            </label>
                        </span>
                    </div>
                </div>

                {/* Company Website */}
                <label className="col-md-4 pd10botbuilder">Company website</label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input
                            type="text"
                            className="form-control"
                            name="website"
                            value={props.companyInfo.website}
                            onChange={(event) => handleCompanyInfoInput(event)}
                        />
                    </div>
                </div>

                {/* Word Limit */}
                <label className="col-md-4 pd10botbuilder">
                    Maximum number of words per response<br />
                    <strong>(We suggest 50 words)</strong>
                </label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input
                            type="number"
                            min="0"
                            className="form-control"
                            name="limit"
                            value={props.companyInfo.limit || ''}
                            onChange={(event) => handleCompanyInfoInput(event)}
                        />
                    </div>
                </div>
                {props.botType === 'Voice_Turbo' &&
                    <div className='col-md-12'>
                        <div
                            className="kt-menu__link btn linear-gradient yr-submitbtn"
                            style={{ minHeight: 0, fontSize: '1rem', padding: 6, height: 30 }}
                            onClick={handleAddAssistant}
                        >
                            <span className="kt-menu__link-text">
                                <i className="fa fa-plus" aria-hidden="true"></i>Connect to additional assistants
                            </span>
                        </div>
                    </div>
                }
                <div className='clearfix'></div>
                {/* Assistants Box */}
                <div className='col-md-12'>
                    <div className={`row ${classes.assistantsBox}`}>
                        {assistants && assistants.map((assistant, index) => (
                            <div key={index} className={`col-md-5 ${classes.assistantBox}`}>
                                {/* Delete Icon */}
                                <button
                                    onClick={() => handleDeleteAssistant(index)}
                                    className={classes.deleteButton}
                                    style={{ background: 'none', border: 'none', color: 'red', cursor: 'pointer' }}
                                >
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                                <label>Assistant:</label>
                                <select
                                    value={assistant.workspaceId}
                                    onChange={(e) => handleInputChange(index, 'assistant', e.target.value)}
                                    style={{ display: 'block', marginBottom: 10 }}
                                >
                                    <option value="">Select Assistant</option>
                                    {props.turboList.length && props.turboList
                                        .filter((tl) => tl.code !== props.workspace_id) // Filter the assistants
                                        .map((tl) => (
                                            <option key={tl.code} value={tl.wid}>{tl.name}</option>
                                        ))
                                    }
                                </select>
                                <label>Message:</label>
                                <textarea
                                    value={assistant.message}
                                    onChange={(e) => handleInputChange(index, 'message', e.target.value)}
                                    style={{ display: 'block', marginBottom: 10, width: '100%', height: '80px' }} // Adjust size as needed
                                />

                                <label>Description:</label>
                                <textarea
                                    value={assistant.description}
                                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                    style={{ display: 'block', marginBottom: 10, width: '100%', height: '80px' }} // Adjust size as needed
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="box-title">Basic Info</div>
            {renderComapnyInfo()}
        </>
    );
};

export default CompanyInformation;
