import React, { useEffect } from "react";
import { Form, Message, Input,Checkbox } from "semantic-ui-react";
import api from "../../api";
import ConfirmDialog from "../Modal/ConfirmDialog";
import { useState } from "react";

class Zaptoken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token:"",
      successMessage: null,
      errors: null,
      inputkey:1,
      loading:false,
      message:""
    };
  }
  componentDidMount() {
    this.loadData();
  }
  loadData=()=>{
    api.dialogflow.getZaptoken().then((res)=>{
      console.log(res,"tokendata");
      if(res.status){
        this.setState({token:res.token}) ;
      }

    }).catch(err=>{
      console.log(err);
    });
      
  }
 
  

finalSave = (e) =>{
  this.setState({loading:true});
  let formData={
      stchatstatus:this.state.token,
  }
  api.dialogflow.saveZapToken(formData).then(resp=>{
      if(resp.status){
         this.setState({successMessage:"Token generated.",loading:false,token:resp.token});  
      }else{
        this.setState({loading:false,successMessage:null,errors:"There is a error."});
      }
      setTimeout(() => {
        this.setState({loading:false,successMessage:null,errors:null});
      }, 4000);
  });
}

copyLink = (id) => {
  const linktocopy = document.getElementById(id).innerText;
  navigator.clipboard.writeText(linktocopy);
  let linkcopysuccess = document.getElementById('tokencopysuccess');
  linkcopysuccess.style.display = 'inline';
  setTimeout(() => {
      linkcopysuccess.style.display = 'none';
  }, 2000);
}


  
  render() {
    const errorLabel = <div className="red" pointing={"true"} />;

    return (
      <React.Fragment>
        <div className="row">
        {this.state.loading && <div className="ui loading form"></div>}
          <div className="col-lg-12 col-md-12 col-sm-12">
            {this.state.successMessage && (
              <Message color={"green"}>{this.state.successMessage}</Message>
            )}
            <div className="kt-portlet__body kt-portlet__body--fit ">
              <div class="form-group row">
              <div style={{display:"flex", justifyContent:"space-between"}} class="col-lg-16 col-md-12 col-sm-12 form-group-sub d-flex  flex-column">
              <p><label> Api Token</label></p>
              <div style={{display:"block",margin:"0 auto"}}>
              {(this.state.token && this.state.token.length>0) ? 
              (<div>
                <strong style={{fontWeight:'600'}}>Api Token :</strong>&nbsp;
                <span id="tokeval">{this.state.token}</span> &nbsp;
                <i className="fa fa-copy" style={{ marginLeft: 20, cursor: 'pointer' }} onClick={() => this.copyLink('tokeval')}></i>
                <i id="tokencopysuccess" className="fa fa-check" style={{ color: 'green', fontSize: '1.3em !important', marginLeft: 5, display: 'none' }}></i>
                 </div>) : 
                 (<button className="btn btn-block  linear-gradient yr-submitbtn btn-sm" onClick={(e)=>this.finalSave(e)}>Generate Token</button>)}
              </div>
             
                </div>
                            
              </div>
              
            
            </div>
          </div>
        </div>
       
      </React.Fragment>
    );
  }
}



export default Zaptoken;
