import React from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
export const DatatableRow = (props) => (
  <Table.Row>
    <Table.Cell>
      {props.list.user_type != "main" ? (
        <span>
          <a
            onClick={() => props.deleteCompletedChat(props.list.id)}
            title="Delete"
            className="btn btn-icon gray"
          >
            <i className="la la-trash"></i>
          </a>{" "}
        </span>
      ) : (
        ""
      )}
    </Table.Cell>
    <Table.Cell>
      {props.list.email == ""
        ? props.list.isd_code + " " + props.list.phone_number
        : props.list.email}
    </Table.Cell>
    <Table.Cell>
      {props.list.location 
        ? props.list.location
        : 'All'}
    </Table.Cell>
    <Table.Cell>
      {props.list.web_branch 
        ? props.list.web_branch
        : 'Any Branch'}
    </Table.Cell>
    
  </Table.Row>
);

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
